import React from "react";

function About() {
  return (
    <div>
     <h3>This Is About</h3>
    </div>
  );
}

export default About;